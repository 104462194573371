<template>
    <section class="content">
        <div class="container-fluid">
            <div class="row">
                <div class="col-12">
                <h4>Pilih salah satu Jenis Try Out</h4>
                </div>
            </div>
            <br>
            <div class="row">
                <div class="col-12 col-sm-6 col-md-6 d-flex align-items-stretch flex-column">
                    <div class="card bg-light d-flex flex-fill">
                        <div class="card-header text-muted border-bottom-0">
                        
                        </div>
                        <div class="card-body pt-0">
                        <div class="row">
                            <div class="col-12">
                            <h2 class="lead"><b>OFFLINE</b></h2>
                            <p class="text-muted text-sm"><b>Try Out</b> khusus peserta didik Taruna Cendekia Program Offline </p> 
                            <p v-if="ujian.qty_offline < 1" class="text-red">Try out tidak tersedia</p>                           
                            <p v-if="ujian.qty_offline >= 1">{{ ujian.qty_offline }} Try out tersedia</p>
                            </div>
                        </div>
                        </div>
                        <div class="card-footer">
                        <div class="text-right" v-if="ujian.qty_offline < 1"></div>
                        <div class="text-right" v-if="ujian.qty_offline >= 1">
                            <router-link :to="{
                                name: 'Daftar Try Out',
                                params: { filter: { jenis: 'offline', id: ujian.id, 'type': 'ujian' }},
                            }" class="btn btn-sm btn-primary">
                            <i class="fas fa-arrow-right"></i> Detail
                            </router-link>
                        </div>
                        </div>
                    </div>
                </div>
                <div class="col-12 col-sm-6 col-md-6 d-flex align-items-stretch flex-column">
                    <div class="card bg-light d-flex flex-fill">
                        <div class="card-header text-muted border-bottom-0">
                        
                        </div>
                        <div class="card-body pt-0">
                        <div class="row">
                            <div class="col-12">
                            <h2 class="lead"><b>ONLINE</b></h2>
                            <p class="text-muted text-sm"><b>Try Out</b> khusus peserta didik Taruna Cendekia Program Online </p>
                            <p v-if="ujian.qty_online < 1">Try Out tidak tersedia</p>
                            <p v-if="ujian.qty_online >= 1">{{ ujian.qty_online }} Try Out tersedia</p>
                            </div>
                        </div>
                        </div>
                        <div class="card-footer">
                        <div class="text-right" v-if="ujian.qty_online < 1"></div>
                        <div class="text-right" v-if="ujian.qty_online >= 1">
                            <router-link :to="{
                                name: 'Daftar Try Out',
                                params: { filter: { jenis: 'online', id: ujian.id, 'type': 'ujian' }},
                            }" class="btn btn-sm btn-primary">
                            <i class="fas fa-arrow-right"></i> Detail
                            </router-link>
                        </div>
                        </div>
                    </div>
                </div>
            </div>
            
        </div>
    </section>
</template>
<script>
import { auth, authFetch } from "@/libs/hxcore";
export default {
    name: 'Ujian',
    data: () => ({
        ujian: {},
        info: []
    }),
    created: function () {
        // this.loadUjian();
        var info = auth.user();
        if(info)this.info = info;
    },
    methods: {
        loadUjian(){
            let admin = this.info.role_id == '1' ? '/1' : '';
            authFetch("/siswa/ujian" + admin).then((res) => {
                res.json().then((json) => {
                if (json.success) {
                    this.ujian = json.data;
                }
                });
            });
        }
    },
    mounted(){
        this.loadUjian();
    },
}
</script>
<style type="text/css" scoped>
</style>
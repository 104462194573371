var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"content"},[_c('div',{staticClass:"container-fluid"},[_vm._m(0),_c('br'),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12 col-sm-6 col-md-6 d-flex align-items-stretch flex-column"},[_c('div',{staticClass:"card bg-light d-flex flex-fill"},[_c('div',{staticClass:"card-header text-muted border-bottom-0"}),_c('div',{staticClass:"card-body pt-0"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[_vm._m(1),_vm._m(2),(_vm.ujian.qty_offline < 1)?_c('p',{staticClass:"text-red"},[_vm._v("Try out tidak tersedia")]):_vm._e(),(_vm.ujian.qty_offline >= 1)?_c('p',[_vm._v(_vm._s(_vm.ujian.qty_offline)+" Try out tersedia")]):_vm._e()])])]),_c('div',{staticClass:"card-footer"},[(_vm.ujian.qty_offline < 1)?_c('div',{staticClass:"text-right"}):_vm._e(),(_vm.ujian.qty_offline >= 1)?_c('div',{staticClass:"text-right"},[_c('router-link',{staticClass:"btn btn-sm btn-primary",attrs:{"to":{
                            name: 'Daftar Try Out',
                            params: { filter: { jenis: 'offline', id: _vm.ujian.id, 'type': 'ujian' }},
                        }}},[_c('i',{staticClass:"fas fa-arrow-right"}),_vm._v(" Detail ")])],1):_vm._e()])])]),_c('div',{staticClass:"col-12 col-sm-6 col-md-6 d-flex align-items-stretch flex-column"},[_c('div',{staticClass:"card bg-light d-flex flex-fill"},[_c('div',{staticClass:"card-header text-muted border-bottom-0"}),_c('div',{staticClass:"card-body pt-0"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[_vm._m(3),_vm._m(4),(_vm.ujian.qty_online < 1)?_c('p',[_vm._v("Try Out tidak tersedia")]):_vm._e(),(_vm.ujian.qty_online >= 1)?_c('p',[_vm._v(_vm._s(_vm.ujian.qty_online)+" Try Out tersedia")]):_vm._e()])])]),_c('div',{staticClass:"card-footer"},[(_vm.ujian.qty_online < 1)?_c('div',{staticClass:"text-right"}):_vm._e(),(_vm.ujian.qty_online >= 1)?_c('div',{staticClass:"text-right"},[_c('router-link',{staticClass:"btn btn-sm btn-primary",attrs:{"to":{
                            name: 'Daftar Try Out',
                            params: { filter: { jenis: 'online', id: _vm.ujian.id, 'type': 'ujian' }},
                        }}},[_c('i',{staticClass:"fas fa-arrow-right"}),_vm._v(" Detail ")])],1):_vm._e()])])])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[_c('h4',[_vm._v("Pilih salah satu Jenis Try Out")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('h2',{staticClass:"lead"},[_c('b',[_vm._v("OFFLINE")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticClass:"text-muted text-sm"},[_c('b',[_vm._v("Try Out")]),_vm._v(" khusus peserta didik Taruna Cendekia Program Offline ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('h2',{staticClass:"lead"},[_c('b',[_vm._v("ONLINE")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticClass:"text-muted text-sm"},[_c('b',[_vm._v("Try Out")]),_vm._v(" khusus peserta didik Taruna Cendekia Program Online ")])
}]

export { render, staticRenderFns }